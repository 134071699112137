/* html, body {
  overflow-x: hidden;
} */

/* universal */

/* end-universal */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600;700;800;900&display=swap');

.row>* {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

h1, h2, h3, h4, h5, h6, p, li, a {
  font-family: 'Roboto Slab', serif;
}

.navbar {
  background-color: #fff !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  transition: .5s ease-in-out;
}

.navbar.active {
  background-color: #fff !important;
  box-shadow: 0px 1px 5px #000000;
}

.navbar.active .activeLink {
  color: #000 !important;
  z-index: 10000 !important;
}

.navbar.active .login-signup-area  {
  background-color: #fff !important;
  border-bottom-left-radius: 0px !important;

}

.navbar.active .signup-area-design {
  background-color: #fff !important;
}

.navbar.active .radiusRight { 
  transition: .3s ease-in-out;
}

.navbar-brand {
  padding-top: 0px !important; 
  padding-bottom: 0px !important;
  border: 2px solid #fff;
}

.navbar.active .radiusRight {
  background-color: #db107b;
  position: absolute;
  height: 100%;
  width: 67%;
  z-index: -1000;
  border-bottom-right-radius: 80px !important;
}

.navbar .container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.navbar .activeLink {
  color: #000 !important;
  font-weight: 700;
}

.navbar .nav-link {
  color: #000;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 700;
}

.navbar.active .nav-link {
  color: #fff !important;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 700;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
  font-weight: 700;
  padding: 0px 15px;
}

.navbar-light .navbar-brand {
  margin-left: 100px;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0 0;
  margin: 0;
  font-size: 1rem;
  color: #373a3c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1pxsolidrgba(0,0,0,.15);
}


.login-signup-area {
  background-color: #1c1f86;
  padding: 30px 150px;
  border-bottom-left-radius: 70px;
}



.signup-area-design {
  background-color: #272b8c;
  height: 20px;
  width: 140px;
  position: absolute;
  bottom: 0px;
  right: 50px;
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  z-index: 1;
}

.login-signup-area .nav-link {
  font-size: 22px !important;
}

.login-area {
  background-color: #db107b;
  box-shadow: 0px 0px 6px #000;
  margin-right: 20px;
  padding: 3px 20px !important;
  border-radius: 15px;
  text-align: center;
  color: #fff !important;
  font-weight: 600;
  transition: .3s linear;
}
.login-area:hover,
.login-area:hover {
  background-color: #1c1f86;
  color: #fff !important;
}

.signup-area{
  background-color: #fff;
  margin-left: 20px;
  padding: 3px 15px !important;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  color: #000 !important;
  font-weight: 600;
  transition: .3s linear;
}

.signup-area:hover,
.signup-area:hover {
  background-color: #db107b;
  color: #fff !important;
}

.leads-sign-up-form {
  position: absolute; 
  color: #fff; 
  font-size: 12px;
  top: 100px;
  right: 102px;
  z-index: 10;
}


/* banner start */
.banner-img img {
  margin-top: -10px;
  width: 100%;
  right: 0px;
  position: relative;
  border-bottom-left-radius: 50px;
}
.welcome-message .left {
  background-color: #db107b;
  z-index: -1;
  margin-top: -100px;
  padding-top: 50px !important;
  padding-bottom: 30px !important;
  border-bottom-right-radius: 50px;
}

.welcome-message h1 {
  color: #fff;
  text-align: left;
  margin-left: 100px;
  font-weight: 600;
}

.welcome-message p {
  color: #eee;
  text-align: left;
  margin-left: 100px;
}

.banner-text {
  padding-top: 160px;
}

.banner-text h1{
  font-size: 35px;
  font-weight: 600;
  color: #000;
}
.banner-text h2{
  font-style: italic;
  font-size: 25px;
}

.cards-logo img {
 position: absolute;
 width: 100px;
 right: 20px;
}

/* endbanner start */

/* how-to-activate */
.how-to-activate {
  margin-top: 60px;
  margin-bottom: 30px;
}

.how-to-activate h1 {
  font-weight: 400;
  font-size: 25px;
  color: #000;
}
.how-to-activate .cursiveText {
  font-size: 30px;
  font-weight: 500;
  font-style: italic;
  color: #000;
}
.how-to-activate h3 { 
  font-weight: 500;
  color: #000;
  font-style: italic;
}
.login h4 {
  text-transform: uppercase;
  color: #000;
  font-size: 16px;
  font-weight: 600;
} 
.login img {
  width: 70%;
  padding-bottom: 10px;
}
.profile-page h4 {
  text-transform: uppercase;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.profile-page img {
  width: 70%;
  padding-bottom: 10px;
}

.welcome-again-text p {
  font-weight: 700;
  padding-top: 20px;
}
.welcome-again-text button {
  background-color: #db107b;
  border-radius: 5px;
  padding: 7px 18px;
  border: 0px;
  transition: .5s ease-in-out;
}

.welcome-again-text button:hover,
.welcome-again-text button:focus {
  background-color: #1c1f86;
}

.welcome-again-text button a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 25px;  
  transition: .5s ease-in-out;
}

.welcome-again-text button a:hover,
.welcome-again-text button a:focus {
  color: #fff;
}

.howToActivateFirstrightsideShape {
  background-color: #1c1f86;
  height: 450px;
  width: 280px;
  position: absolute;
  right: 0;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  z-index: -1;
}

.howToActivateFirstsmallShape {
  background-color: #272b8c;
  z-index: 1;
  height: 250px;
  width: 250px;
  position: absolute;
  right: 0;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}

.howToActivateSecondrightsideShape {
  background-color: #1c1f86;
  height: 350px;
  width: 280px;
  position: absolute;
  right: 0;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  z-index: -1;
}

.howToActivateSecondsmallShape {
  background-color: #272b8c;
  z-index: 1;
  height: 200px;
  width: 250px;
  position: absolute;
  right: 0;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}

.choosePinrightsideShape {
  background-color: #1c1f86;
  height: 450px;
  width: 280px;
  position: absolute;
  right: 0;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  z-index: -1;
}

.choosePinsmallShape {
  background-color: #272b8c;
  z-index: 1;
  height: 300px;
  width: 250px;
  position: absolute;
  right: 0;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}

.spendingLimitsrightsideShape {
  background-color: #1c1f86;
  height: 400px;
  width: 280px;
  position: absolute;
  right: 0;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  z-index: -1;
}

.spendingLimitssmallShape {
  background-color: #272b8c;
  z-index: 1;
  height: 250px;
  width: 250px;
  position: absolute;
  right: 0;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}

/* end-how-to-activate */

/*  */
/*  */

/* terms-conditions */
.terms-condition {
  margin-top: 30px;
  margin-bottom: 30px;
}

.terms-condition h1 {
  font-size: 30px;
  font-weight: 500;
  color: #000;
}

/* .terms a::before{
  content:url('./assets/pdf-icon.png') ;
  width: 20px;
} */

.pdf-icons .fa-file-pdf {
 color: red;
}

.terms span {
  color: #000;
  font-size: 18px;
  margin-left: 5px;
  position: relative;
  top: -4px;
  line-height: 40px;
}

.pdf-icons {
  margin-left: 10px;
  padding: 10px 0px;
}

.pdf-icons a {
  text-decoration: none;
}

.terms-condition__rightsideShape {
  background-color: #1c1f86;
  height: 300px;
  width: 320px;
  position: absolute;
  right: 0;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  z-index: -1;
}

.terms-condition__smallShape {
  background-color: #272b8c;
  z-index: 1;
  height: 180px;
  width: 250px;
  position: absolute;
  right: 0;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}

/* end-terms-conditions */

.footer {
  background-color: #db107b;
  color: #eee;
  margin-top: 150px;
}

.FloatingSignIn {
  display: none;
}



@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .banner-text {
    padding-top: 140px !important;
  }
}

/* @media only screen and (max-width: 657px) and (max-width: 1040px) {
  .banner-text {
    padding-top: 10px !important;
  }
  .banner-text h1 {
    font-size: 25px;
  }
  .banner-text h2 {
    font-size: 20px;
  }


} */
/* 
Great Vibes
Allura 

*/