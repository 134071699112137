

/* iPad */
@media only screen and (max-width: 1024px) {
  .navbar-light .navbar-brand {
    margin-left: 7px;
  }
  .navbar-light .navbar-toggler {
  margin-right: 7px;
  }
  .login-signup-area {
      display: none;
  }
  .signup-area-design {
      display: none;
  }
  .banner-img img {
    margin-top: -10px;
    width: 100%;
    right: 0px;
    top: 10px;
    position: relative;
    border-bottom-left-radius: 50px;
  }
  .banner-text {
      padding-top: 70px;
  }
  .banner-text h1 {
    font-size: 25px;
    padding: 10px 0px;
    font-weight: 700;
  }
  .banner-text h2 {
      font-size: 20px;
      padding: 10px 0px 40px 0px;
      font-weight: 500;
  }
  .welcome-message .left {
      background-color: #db107b;
      z-index: -1;
      margin-top: -45px;
      padding-top: 70px !important;
      padding-bottom: 10px !important;
  }
  .welcome-message h1 {
      color: #fff;
      text-align: left;
      margin-left: 30px;
      font-weight: 700;
  }
  .welcome-message p {
      color: #eee;
      text-align: left;
      margin-left: 10px;
      padding: 10px 20px;
  }
  .cards-logo img {
      position: relative;
      width: 70px;
      right: -180px;
      bottom: -50px;
  }

  .container{
    max-width: 100%;
}
  .how-to-activate h1 {
      font-weight: 400;
      font-size: 25px;
      color: #000;
      text-align: center;
    }
    .how-to-activate .cursiveText {
      font-size: 30px;
      font-weight: 500;
      font-style: italic;
      color: #000;
      text-align: center;
    }
    .how-to-activate h3 { 
      font-weight: 500;
      color: #000;
      font-style: italic;
      text-align: center;
    }
    .login {
      width: 50%;
    }
    .login img {
      width: 90%;
      padding-bottom: 10px;
    }
    .profile-page {
      width: 50%;
    }
    .profile-page img {
      width: 90%;
      padding-bottom: 10px;
    }
    .howToActivateFirstrightsideShape {
      background-color: #1c1f86;
      height: 100px;
      width: 100%;
      position: relative;
      bottom: 0px;
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 80% !important;
      border-bottom-right-radius: 80% !important;
      z-index: -1;
    }
    
    .howToActivateFirstsmallShape {
      background-color: #272b8c;
      z-index: 1;
      height: 60px;
      width: 100%;
      position: relative;
      bottom: 0px;
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 80% !important;
      border-bottom-right-radius: 80% !important;
    }

    .howToActivateSecondrightsideShape {
      background-color: #1c1f86;
      height: 100px;
      width: 100%;
      position: relative;
      bottom: 0px;
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 80% !important;
      border-bottom-right-radius: 80% !important;
      z-index: -1;
    }
    
    .howToActivateSecondsmallShape {
      background-color: #272b8c;
      z-index: 1;
      height: 60px;
      width: 100%;
      position: relative;
      bottom: 0px;
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 80% !important;
      border-bottom-right-radius: 80% !important;
    }
    .choosePinrightsideShape {
      background-color: #1c1f86;
      height: 100px;
      width: 100%;
      position: relative;
      bottom: 0px;
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 80% !important;
      border-bottom-right-radius: 80% !important;
      z-index: -1;
    }
    
    .choosePinsmallShape {
      background-color: #272b8c;
      z-index: 1;
      height: 60px;
      width: 100%;
      position: relative;
      bottom: 0px;
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 80% !important;
      border-bottom-right-radius: 80% !important;
    }

    .spendingLimitsrightsideShape {
      background-color: #1c1f86;
      height: 100px;
      width: 100%;
      position: relative;
      bottom: 0px;
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 80% !important;
      border-bottom-right-radius: 80% !important;
      z-index: -1;
    }
    .spendingLimitssmallShape {
      background-color: #272b8c;
      z-index: 1;
      height: 60px;
      width: 100%;
      position: relative;
      bottom: 0px;
      right: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 80% !important;
      border-bottom-right-radius: 80% !important;
    }

    .welcome-again-text button {
      background-color: #db107b;
      border-radius: 5px;
      padding: 7px 18px;
      border: 0px;
      transition: .5s ease-in-out;
    }
    
    .welcome-again-text button:hover,
    .welcome-again-text button:focus {
      background-color: #1c1f86;
    }
    
    .welcome-again-text button a {
      text-decoration: none;
      color: #fff;
      font-weight: 600;
      font-size: 25px;  
      transition: .5s ease-in-out;
    }
    
    .welcome-again-text button a:hover,
    .welcome-again-text button a:focus {
      color: #fff;
    }
    .spending-limits-subheading {
      text-align: center;
    }

    .listItem {
      margin: 0px 10px !important;
    }

    .listItem li {
      margin: 0px 10px !important;
    }

    .terms-condition h1 {
      text-align: center;
    }
    .terms-condition__rightsideShape {
      display: none;
    }
    
    .terms-condition__smallShape {
      display: none;
    }
    .terms span {
      font-size: 15px;
      margin-left: 5px;
      position: relative;
      top: -10px;
    }
    .footer {
      background-color: #db107b;
      color: #eee;
      margin-top: 20px;
      padding-bottom: 50px;
    }
    .FloatingSignIn {
      display: block;
      position: fixed;
      width: 100%;
      bottom: 5px;
      padding: 2px 5px;
      width: 100% !important;
      border-radius: 0px !important;
    }
    .FloatingSignIn button {
      background-color: #1c1f86 !important;
      border: none;
      box-shadow: 0px 0px 8px #000;
      padding: 5px 20px;
      width: 100% !important;
      border-radius: 0px !important;
    }
    .FloatingSignIn button a {
      font-size: 20px !important;
    }
    .navbar.active .radiusRight {
      background-color: #db107b;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1000;
      border-bottom-right-radius: 0px !important;
    }
}



/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 595px) {
    .navbar-light .navbar-brand {
        margin-left: 5px;
    }
    /* .navbar-light .navbar-toggler {
    margin-right: 5px;
    } */
    .navbar-nav .nav-link {
      color: #000;
      text-transform: uppercase;
      font-weight: 600;
    }
    .navbar .container-fluid {
      padding-right: 0px !important;
      padding-left: 0px !important;
      padding: 5px 0px;
  }

    .dropdown-item {
      font-weight: 500;
      color: #000;
  }
  
    .login-signup-area {
        display: none;
    }
    .signup-area-design {
        display: none;
    }
    .banner-text {
        padding-top: 20px;
    }
    .banner-text h1 {
       font-size: 25px;
       padding: 10px 0px;
       font-weight: 700;
    }
    .banner-text h2 {
        font-size: 20px;
        padding: 10px 0px 40px 0px;
        font-weight: 500;
    }
    .welcome-message .left {
        background-color: #db107b;
        z-index: -1;
        margin-top: -50px;
        padding-top: 60px !important;
        padding-bottom: 30px !important;
    }
    .welcome-message h1 {
        color: #fff;
        text-align: left;
        margin-left: 120px;
        font-weight: 700;
    }
    .welcome-message p {
        color: #eee;
        text-align: left;
        margin-left: 10px;
        padding: 10px 20px;
    }
    .cards-logo img {
        position: absolute;
        width: 70px;
        right: 150px;
    }
    .how-to-activate h1 {
        font-weight: 400;
        font-size: 25px;
        color: #000;
        text-align: center;
      }
      .how-to-activate .cursiveText {
        font-size: 30px;
        font-weight: 500;
        font-style: italic;
        color: #000;
        text-align: center;
      }
      .how-to-activate h3 { 
        font-weight: 500;
        color: #000;
        font-style: italic;
        text-align: center;
      }

      .login {
        width: 100%;
        padding-bottom: 10px;
      }
      .profile-page {
        width: 100%;
      }
      
      .login img {
        width: 80%;
        padding-bottom: 10px;
      }
      .profile-page img {
        width: 80%;
        padding-bottom: 10px;
      }
      .howToActivateFirstrightsideShape {
        background-color: #1c1f86;
        height: 100px;
        width: 100%;
        position: relative;
        bottom: 0px;
        right: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 80% !important;
        border-bottom-right-radius: 80% !important;
        z-index: -1;
      }
      
      .howToActivateFirstsmallShape {
        background-color: #272b8c;
        z-index: 1;
        height: 60px;
        width: 100%;
        position: relative;
        bottom: 0px;
        right: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 80% !important;
        border-bottom-right-radius: 80% !important;
      }

      .howToActivateSecondrightsideShape {
        background-color: #1c1f86;
        height: 100px;
        width: 100%;
        position: relative;
        bottom: 0px;
        right: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 80% !important;
        border-bottom-right-radius: 80% !important;
        z-index: -1;
      }
      
      .howToActivateSecondsmallShape {
        background-color: #272b8c;
        z-index: 1;
        height: 60px;
        width: 100%;
        position: relative;
        bottom: 0px;
        right: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 80% !important;
        border-bottom-right-radius: 80% !important;
      }
      .choosePinrightsideShape {
        background-color: #1c1f86;
        height: 100px;
        width: 100%;
        position: relative;
        bottom: 0px;
        right: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 80% !important;
        border-bottom-right-radius: 80% !important;
        z-index: -1;
      }
      
      .choosePinsmallShape {
        background-color: #272b8c;
        z-index: 1;
        height: 60px;
        width: 100%;
        position: relative;
        bottom: 0px;
        right: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 80% !important;
        border-bottom-right-radius: 80% !important;
      }

      .spendingLimitsrightsideShape {
        background-color: #1c1f86;
        height: 100px;
        width: 100%;
        position: relative;
        bottom: 0px;
        right: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 80% !important;
        border-bottom-right-radius: 80% !important;
        z-index: -1;
      }
      .spendingLimitssmallShape {
        background-color: #272b8c;
        z-index: 1;
        height: 60px;
        width: 100%;
        position: relative;
        bottom: 0px;
        right: 0;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 80% !important;
        border-bottom-right-radius: 80% !important;
      }
      .welcome-again-text button {
        background-color: #db107b;
        border-radius: 5px;
        padding: 7px 18px;
        border: 0px;
        transition: .5s ease-in-out;
      }
      
      .welcome-again-text button:hover,
      .welcome-again-text button:focus {
        background-color: #1c1f86;
      }
      
      .welcome-again-text button a {
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        font-size: 25px;  
        transition: .5s ease-in-out;
      }
      
      .welcome-again-text button a:hover,
      .welcome-again-text button a:focus {
        color: #fff;
      }
      .listItem {
        margin: 0px 10px !important;
      }

      .listItem li {
        margin: 0px 10px !important;
      }

      .terms-condition h1 {
        text-align: center;
      }
      .terms-condition__rightsideShape {
        display: none;
      }
      
      .terms-condition__smallShape {
        display: none;
      }
      .terms span {
        font-size: 15px;
        margin-left: 5px;
        position: relative;
        top: -10px;
      }
      .footer {
        background-color: #db107b;
        color: #eee;
        margin-top: 20px;
        padding-bottom: 50px;
      }
      .FloatingSignIn {
        display: block;
        position: fixed;
        width: 100%;
        bottom: 5px;
        padding: 2px 5px;
        width: 100% !important;
        border-radius: 0px !important;
      }
      .FloatingSignIn button {
        background-color: #db107b !important;
        border: none;
        box-shadow: 0px 0px 8px #000;
        padding: 5px 20px;
        width: 100% !important;
        border-radius: 0px !important;
      }
      .FloatingSignIn button a {
        font-size: 20px !important;
      }
      .navbar.active .radiusRight {
        background-color: #fff;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1000;
        border-bottom-right-radius: 0px !important;
      }
      
}

/* Small screen devices (600px and above) */
@media only screen and (max-width: 600px) {
  .navbar-light .navbar-brand {
    margin-left: 5px;
}
.navbar.active .radiusRight {
  background-color: #db107b;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1000;
  border-bottom-right-radius: 0px !important;
}
.navbar .container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding: 5px 0px;
}
.navbar-light .navbar-toggler {
margin-right: 5px;
}
.login-signup-area {
    display: none;
}
.signup-area-design {
    display: none;
}
.banner-text {
    padding-top: 20px;
}
.banner-text h1 {
   font-size: 25px;
   padding: 10px 0px;
   font-weight: 700;
}
.banner-text h2 {
    font-size: 20px;
    padding: 10px 0px 40px 0px;
    font-weight: 500;
}
.welcome-message .left {
    background-color: #db107b;
    z-index: -1;
    margin-top: -50px;
    padding-top: 60px !important;
    padding-bottom: 30px !important;
}
.welcome-message h1 {
    color: #fff;
    text-align: left;
    margin-left: 120px;
    font-weight: 700;
}
.welcome-message p {
    color: #eee;
    text-align: left;
    margin-left: 10px;
    padding: 10px 20px;
}
.cards-logo img {
    position: absolute;
    width: 70px;
    right: 150px;
}
.how-to-activate h1 {
    font-weight: 400;
    font-size: 25px;
    color: #000;
    text-align: center;
  }
  .how-to-activate .cursiveText {
    font-size: 30px;
    font-weight: 500;
    font-style: italic;
    color: #000;
    text-align: center;
  }
  .how-to-activate h3 { 
    font-weight: 500;
    color: #000;
    font-style: italic;
    text-align: center;
  }
  .login img {
    width: 80%;
    padding-bottom: 10px;
  }
  .profile-page img {
    width: 80%;
    padding-bottom: 10px;
  }
  .howToActivateFirstrightsideShape {
    background-color: #1c1f86;
    height: 100px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
    z-index: -1;
  }
  
  .howToActivateFirstsmallShape {
    background-color: #272b8c;
    z-index: 1;
    height: 60px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
  }

  .howToActivateSecondrightsideShape {
    background-color: #1c1f86;
    height: 100px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
    z-index: -1;
  }
  
  .howToActivateSecondsmallShape {
    background-color: #272b8c;
    z-index: 1;
    height: 60px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
  }
  .choosePinrightsideShape {
    background-color: #1c1f86;
    height: 100px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
    z-index: -1;
  }
  
  .choosePinsmallShape {
    background-color: #272b8c;
    z-index: 1;
    height: 60px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
  }

  .spendingLimitsrightsideShape {
    background-color: #1c1f86;
    height: 100px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
    z-index: -1;
  }
  .spendingLimitssmallShape {
    background-color: #272b8c;
    z-index: 1;
    height: 60px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
  }

  .welcome-again-text button {
    background-color: #db107b;
    border-radius: 5px;
    padding: 7px 18px;
    border: 0px;
    transition: .5s ease-in-out;
  }
  
  .welcome-again-text button:hover,
  .welcome-again-text button:focus {
    background-color: #1c1f86;
  }
  
  .welcome-again-text button a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 25px;  
    transition: .5s ease-in-out;
  }
  
  .welcome-again-text button a:hover,
  .welcome-again-text button a:focus {
    color: #fff;
  }

  .listItem {
    margin: 0px 7px 0px 3px !important;
  }

  .listItem li {
    margin: 0px 7px 0px 3px !important;
  }

  .terms-condition h1 {
    text-align: center;
  }
  .terms-condition__rightsideShape {
    display: none;
  }
  
  .terms-condition__smallShape {
    display: none;
  }
  .terms span {
    font-size: 15px;
    margin-left: 5px;
    position: relative;
    top: -10px;
  }
  .footer {
    background-color: #db107b;
    color: #eee;
    margin-top: 20px;
    padding-bottom: 50px;
  }
  .FloatingSignIn {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 5px;
    padding: 2px 5px;
    width: 100% !important;
    border-radius: 0px !important;
  }
  .FloatingSignIn button {
    background-color: #db107b !important;
    border: none;
    box-shadow: 0px 0px 8px #000;
    padding: 5px 20px;
    width: 100% !important;
    border-radius: 0px !important;
  }
  .FloatingSignIn button a {
    font-size: 20px !important;
  }
   
}

/* Medium screen devices (768px and above) */
@media only screen and (max-width: 750px) {
  .navbar-light .navbar-brand {
    margin-left: 5px;
}
.navbar.active {
  background-color: #db107b !important;
  box-shadow: 0px 1px 5px #000000;
}
.navbar .container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding: 5px 0px;
}
.navbar-light .navbar-toggler {
margin-right: 5px;
}
.login-signup-area {
    display: none;
}
.signup-area-design {
    display: none;
}

.banner-img img {
  filter: brightness(50%)
}

.banner-text {
    padding-top: 20px; 
}
.banner-text h1 {
   font-size: 30px;
   padding: 10px 0px;
   font-weight: 700;
   position: absolute;
   color: #eee;
   top: 110px;
   left: 60px;
   z-index: 10;
}
.banner-text h2 {
    font-size: 20px;
    padding: 10px 0px 40px 0px;
    font-weight: 500;
    position: absolute;
    color: #eee;
    top: 160px;
    left: 75px;
    z-index: 10;
}
.welcome-message .left {
    background-color: #db107b;
    z-index: -1;
    margin-top: -50px;
    padding-top: 60px !important;
    padding-bottom: 30px !important;
}
.welcome-message h1 {
    color: #fff;
    text-align: left;
    margin-left: 120px;
    font-weight: 700;
    padding-top: 20px;
}
.welcome-message p {
    color: #eee;
    text-align: left;
    margin-left: 10px;
    padding: 2px 20px;
}
.cards-logo img {
    position: absolute;
    width: 70px;
    right: 150px;
}
.how-to-activate h1 {
    font-weight: 400;
    font-size: 25px;
    color: #000;
    text-align: center;
  }
  .how-to-activate .cursiveText {
    font-size: 30px;
    font-weight: 500;
    font-style: italic;
    color: #000;
    text-align: center;
  }
  .how-to-activate h3 { 
    font-weight: 500;
    color: #000;
    font-style: italic;
    text-align: center;
  }
  .login img {
    width: 80%;
    padding-bottom: 10px;
  }
  .profile-page img {
    width: 80%;
    padding-bottom: 10px;
  }
  .howToActivateFirstrightsideShape {
    margin-top: 20px;
    background-color: #1c1f86;
    height: 100px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
    z-index: -1;
  }
  
  .howToActivateFirstsmallShape {
    background-color: #272b8c;
    z-index: 1;
    height: 60px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
  }

  .howToActivateSecondrightsideShape {
    margin-top: 20px;
    background-color: #1c1f86;
    height: 100px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
    z-index: -1;
  }
  
  .howToActivateSecondsmallShape {
    background-color: #272b8c;
    z-index: 1;
    height: 60px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
  }
  .choosePinrightsideShape {
    margin-top: 150px;
    background-color: #1c1f86;
    height: 100px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
    z-index: -1;
  }
  
  .choosePinsmallShape {
    background-color: #272b8c;
    z-index: 1;
    height: 60px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
  }

  .spendingLimitsrightsideShape {
    margin-top: 20px;
    background-color: #1c1f86;
    height: 100px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
    z-index: -1;
  }
  .spendingLimitssmallShape {
    background-color: #272b8c;
    z-index: 1;
    height: 60px;
    width: 100%;
    position: relative;
    bottom: 0px;
    right: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 80% !important;
    border-bottom-right-radius: 80% !important;
  }

  .welcome-again-text button {
    background-color: #db107b;
    border-radius: 5px;
    padding: 7px 18px;
    border: 0px;
    transition: .5s ease-in-out;
  }
  
  .welcome-again-text button:hover,
  .welcome-again-text button:focus {
    background-color: #1c1f86;
  }
  
  .welcome-again-text button a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 25px;  
    transition: .5s ease-in-out;
  }
  
  .welcome-again-text button a:hover,
  .welcome-again-text button a:focus {
    color: #fff;
  }

  .listItem {
    margin: 0px 7px 0px 3px !important;
  }

  .listItem li {
    margin: 0px 7px 0px 3px !important;
  }

  .terms-condition h1 {
    text-align: center;
  }
  .terms-condition__rightsideShape {
    display: none;
  }
  
  .terms-condition__smallShape {
    display: none;
  }
  .terms span {
    font-size: 15px;
    margin-left: 5px;
    position: relative;
    top: -10px;
  }
  .footer {
    background-color: #db107b;
    color: #eee;
    margin-top: 20px;
    padding-bottom: 50px;
  }
  .FloatingSignIn {
    display: block;
    position: fixed;
    width: 100%;
    bottom: 5px;
    padding: 2px 5px;
    width: 100% !important;
    border-radius: 0px !important;
  }
  .FloatingSignIn button {
    background-color: #db107b !important;
    border: none;
    box-shadow: 0px 0px 8px #000;
    padding: 5px 20px;
    width: 100% !important;
    border-radius: 0px !important;
  }
  .FloatingSignIn button a {
    font-size: 20px !important;
  }

}

@media only screen and (max-width: 850px) {
    .cards-logo img {
        position: absolute;
        width: 70px;
        right: 150px;
    }
    .FloatingSignIn {
      display: block;
      position: fixed;
      width: 100%;
      bottom: 5px;
      padding: 2px 5px;
      width: 100% !important;
      border-radius: 0px !important;
    }
    .FloatingSignIn button {
      background-color: #1c1f86 !important;
      border: none;
      box-shadow: 0px 0px 8px #000;
      padding: 5px 20px;
      width: 100% !important;
      border-radius: 0px !important;
    }
    .FloatingSignIn button a {
      font-size: 20px !important;
    }
}

/* Big screen devices (889px and above) */
@media only screen and (max-width: 889px) {

}


